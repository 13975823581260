<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-partner menu="partner"></submenu-partner>
   
      <div class="submenu-content">
          <h2>Featured Brands</h2>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
              <h4>0-9</h4>
              <div v-for="item in groups.number" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>A</h4>
              <div v-for="item in groups.A" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder" 
                    class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>B</h4>
              <div v-for="item in groups.B" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>C</h4>
              <div v-for="item in groups.C" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>D</h4>
              <div v-for="item in groups.D" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder" 
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-3">
              <h4>E</h4>
              <div v-for="item in groups.E" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>F</h4>
              <div v-for="item in groups.F" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>G</h4>
              <div v-for="item in groups.G" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>H</h4>
              <div v-for="item in groups.H" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>I</h4>
              <div v-for="item in groups.I" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>J</h4>
              <div v-for="item in groups.J" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>K</h4>
              <div v-for="item in groups.K" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-3">
              <h4>L</h4>
              <div v-for="item in groups.L" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>M</h4>
              <div v-for="item in groups.M" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>N</h4>
              <div v-for="item in groups.N" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>O</h4>
              <div v-for="item in groups.O" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>P</h4>
              <div v-for="item in groups.P" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>Q</h4>
              <div v-for="item in groups.Q" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-3">
              <h4>R</h4>
              <div v-for="item in groups.R" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>S</h4>
              <div v-for="item in groups.S" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>T</h4>
              <div v-for="item in groups.T" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>U</h4>
              <div v-for="item in groups.U" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>V</h4>
              <div v-for="item in groups.V" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>W</h4>
              <div v-for="item in groups.W" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>X</h4>
              <div v-for="item in groups.X" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>Y</h4>
              <div v-for="item in groups.Y" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
              <h4>Z</h4>
              <div v-for="item in groups.Z" v-bind:key="item.id" class="padding5">
                  <!-- <a :href="'/products/?mfid='+item.mfid">{{ item.brand_name}}</a> --> {{ item.brand_name}}
                  <span v-if="item.microsite=='yes'">
                    <router-link :to="item.microsite_folder==null? '/partners/microsite/'+item.brand_name.replace(/\s/g, '_').toLowerCase() :'/partners/microsite/'+item.microsite_folder"
                      class="microsite">Microsite</router-link>
                  </span> 
              </div>
            </div>

          </div>

        
      </div>

  </div>
</template>

<script>
import submenuPartner from '../../components/nav/submenuPartner.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from '../../utils/axios-api'
import { ref } from "vue"

export default {
  name: 'Partners',
  components: { submenuPartner, BreadcrumbPage },
  setup() {
    const error = ref(null)
    const items = ref(null)
    const groups = ref({})
    groups.value = {'number':[],'A':[],'B':[],'C':[],'D':[],'E':[],'F':[],
    'G':[],'H':[],'I':[],'J':[],'K':[],'L':[],'M':[],
    'N':[],'O':[],'P':[],'Q':[],'R':[],'S':[],'T':[],
    'U':[],'V':[],'W':[],'X':[],'Y':[],'Z':[]}

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Partners", link: ""})
    breadcrumb_links.value.push({text: "Featured Brands", link: "Partners"})

    var url = "/content/snippets/partner/?limit=1000"
    getAPI.get(url)
    .then((response) => {
      items.value = response.data.items
      let numbers = 'a1234567890'
      
      for (var i=0; i<items.value.length; i++){
        let fchar = items.value[i]["brand_name"].substring(0, 1).toUpperCase()
        
        if (numbers.includes(fchar)) {
          groups.value['number'].push(items.value[i])
        }
        else{
          groups.value[fchar].push(items.value[i])
        }
      }

    })
    .catch(err => {
      error.value = err
    })

    return { error, 
            items, 
            groups,
            breadcrumb_links }
  }
}
</script>

<style scoped>
a {
  color:black;
  text-decoration: none;
}
a:hover {
  color:cornflowerblue;
}
.padding5 {padding:1px 0;}
h4 {padding:10px 0 5px 0;}
.microsite {dsiplay: inline-block; padding-left:10px;color:darkgoldenrod;}

</style>